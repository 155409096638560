.design-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-shrink: 0;
  padding-bottom: 30px;
}

.design-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 10vh;
  /* margin-bottom: 45px; */
  width: 80%;
  justify-content: space-between;
}

h2.active-link {
  /* background-color: #ee1c24; */
  color: #ee1c24;
  text-shadow: 0px 2px 3px rgba(214, 77, 77, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.design-about h4 {
  margin-bottom: 18px;
  font-size: 16px;
  font-family: "MetroSans-Regular";
}

.design-about-desc,
.design-pieces-desc {
  font-family: "MetroSans-Regular";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 60px;
  margin-bottom: 45px;
}
.design-about-approach {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.approach-item {
  width: 45%;
  margin-bottom: 19px;
}

.approach-item h3 {
  font-size: 16px;

  line-height: 20px;
  margin-bottom: 10px;
  font-family: "MetroSans-Regular";
}

.approach-item p {
  font-size: 14px;
  font-family: "MetroSans-Regular";
  font-weight: 400;
  line-height: 18px;
}

.design-pieces-card-item h3 {
  font-family: "MetroSans-Regular";
  font-size: 16px;
  line-height: 20px;
  margin-top: -10px;
}

.design-pieces-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.design-pieces-card-item {
  width: 50%;
  margin-bottom: 30px;
}

.design-pieces-card-item img {
  max-width: 95%;
  height: auto;
}

@media (max-width: 1120px) {
  .approach-item {
    display: block;
    width: 100%;
  }
}
@media (max-width: 768px) and (max-width: 1024px) {
  .design-header h2 {
    padding-right: 5px;
  }

  h4 {
    line-height: 1.2;
  }

  .design-content {
    padding-right: 15px;
  }

  .design-about-approach {
    justify-content: space-around;
  }
  .design-about-desc,
  .design-pieces-desc {
    margin-bottom: 30px;
  }
}
