.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.loading>div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #222147;
  border-color: #222147 transparent transparent transparent;
  border-radius: 50%;
  animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loading>div:nth-child(1) {
  animation-delay: -0.45s;
}

.loading>div:nth-child(2) {
  animation-delay: -0.3s;
}

.loading>div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}