@font-face {
  font-family: "MetroSans-Bold";
  src: url("/assets/fonts/Founders/FoundersGrotesk-Medium.woff2")
    format("woff2");
}

@font-face {
  font-family: "MetroSans-Medium";
  src: url("/assets/fonts/Metro-Sans/metro-sans-medium.eot");
  src: url("/assets/fonts/Metro-Sans/metro-sans-medium.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Metro-Sans/metro-sans-medium.woff2") format("woff2"),
    url("/assets/fonts/Metro-Sans/metro-sans-medium.woff") format("woff"),
    url("/assets/fonts/Metro-Sans/metro-sans-medium.ttf") format("truetype"),
    url("/assets/fonts/Metro-Sans/metro-sans-medium.svg#youworkforthem")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MetroSans-Regular";
  src: url("/assets/fonts/Metro-Sans/metro-sans-regular.eot");
  src: url("/assets/fonts/Metro-Sans/metro-sans-regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Metro-Sans/metro-sans-regular.woff2") format("woff2"),
    url("/assets/fonts/Metro-Sans/metro-sans-regular.woff") format("woff"),
    url("/assets/fonts/Metro-Sans/metro-sans-regular.ttf") format("truetype"),
    url("/assets/fonts/Metro-Sans/metro-sans-regular.svg#youworkforthem")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

html,
#root,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  /* background: #f7f5f2; */
  overflow-x: hidden;
}

h2 {
  width: fit-content;
  padding-right: 30px;
  font-family: "MetroSans-Bold";
  font-size: 24px;
  font-weight: 700;
  height: 32px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
