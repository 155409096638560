.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.home-page-wrapper {
  font-weight: 400;
  color: #000000;
  padding: 0px 130px;
  height: 100%;
}

.home-page-content-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  height: auto;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 10vh;
}

.logo {
  font-size: 38px;
  margin-right: 40px;
  width: 160px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.logo img {
  width: 100%;
  height: auto;
}

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 50px 0 0;
  /* position: absolute; */
}

.home-page-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  /* padding-top: 60px; */
  padding-bottom: 10px;
}

.loader .logo {
  width: 30vw;
}

.loader {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #f7f5f2;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.fadeout {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  -webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
  pointer-events: none;
}

.fadein {
  opacity: 1;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
  height: 100%;
}
/* .slider {
  position: relative;
  width: 200px;
  height: 136px;
  overflow: hidden;
  top: -106px;
  left: 50px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09);
  display: none;
}

.slider .slider-row {
  width: 200px;
  position: absolute;
  height: 136px;
  animation: slide 11s linear infinite;
} */
.link-text-wrapper1,
.link-text-wrapper2,
.link-text-wrapper3,
.link-text-wrapper4 {
  overflow: hidden;
  display: block;
  height: 70px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: normal;
}

.link-text-wrapper1 span {
  -webkit-transform: translate3d(0px, 0%, 0px);
          transform: translate3d(0px, 0%, 0px);
  -webkit-animation: raise-up-text 0.8s ease-out;
          animation: raise-up-text 0.8s ease-out;
}

.link-text-wrapper2 span {
  -webkit-transform: translate3d(0px, 0%, 0px);
          transform: translate3d(0px, 0%, 0px);
  -webkit-animation: raise-up-text 1s ease-out;
          animation: raise-up-text 1s ease-out;
}

.link-text-wrapper3 span {
  -webkit-transform: translate3d(0px, 0%, 0px);
          transform: translate3d(0px, 0%, 0px);
  -webkit-animation: raise-up-text 1.2s ease-out;
          animation: raise-up-text 1.2s ease-out;
}

.link-text-wrapper4 span {
  -webkit-transform: translate3d(0px, 0%, 0px);
          transform: translate3d(0px, 0%, 0px);
  -webkit-animation: raise-up-text 1.3s ease-out;
          animation: raise-up-text 1.3s ease-out;
}

@-webkit-keyframes raise-up-text {
  from {
    -webkit-transform: translate3d(0px, 110%, 0px);
            transform: translate3d(0px, 110%, 0px);
  }

  to {
    -webkit-transform: translate3d(0px, 0%, 0px);
            transform: translate3d(0px, 0%, 0px);
  }
}

@keyframes raise-up-text {
  from {
    -webkit-transform: translate3d(0px, 110%, 0px);
            transform: translate3d(0px, 110%, 0px);
  }

  to {
    -webkit-transform: translate3d(0px, 0%, 0px);
            transform: translate3d(0px, 0%, 0px);
  }
}

@-webkit-keyframes slide {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: -1000px;
  }
}

@keyframes slide {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: -1000px;
  }
}

.home-page-item .link-text-wrapper1:hover ~ .slider,
.link-text-wrapper2:hover ~ .slider,
.link-text-wrapper3:hover ~ .slider,
.link-text-wrapper4:hover ~ .slider {
  display: unset;
}

.home-page-item {
  display: -webkit-flex;
  display: flex;
  position: relative;
  height: 61px;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-family: "MetroSans-Bold";
  font-size: 60px;
  letter-spacing: -0.5px;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.disable-hover {
  display: none !important;
}

.home-page-item span {
  height: 75px;
  font-size: 65px;
  padding-right: 30px;
  font-family: "MetroSans-Bold";
  letter-spacing: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  letter-spacing: -0.5px;
  /* display: block; */
  cursor: pointer;
  color: #000;
}

.active span {
  /* background-color: #ee1c24; */
  color: #ee1c24;
  text-shadow: 0px 2px 4px rgba(214, 77, 77, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.home-page-item span:hover {
  /* background-color: #ee1c24; */
  color: #ee1c24;
  text-shadow: 0px 2px 4px rgba(214, 77, 77, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

a {
  text-decoration: none;
  color: #000000;
}

.image-container {
  height: 25vh;
}

.image-carousel-container {
  width: 25vh;
  margin-top: 20px;
}

/*Mobile styles*/
@media (max-width: 768px) {
  .home-page-wrapper {
    padding: 30px 20px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    max-width: 100vw;
    -webkit-overflow-scrolling: touch;
    height: 100%;

    /* height: calc(100vh - 60px); */
  }

  .home-page-content {
    padding-top: 0px;
    height: 100%;
  }

  .logo {
    font-size: 22px;
    margin-right: 10px;
  }

  .logo img {
    max-width: 100px;
    max-height: 60px;
    object-fit: contain;
  }

  .header {
    padding: 0 0 5vh 0;
  }

  .home-page-item {
    font-size: 32px;
    height: 52px;
  }

  .home-page-item span {
    height: 40px;
    padding-right: 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  /* .slider {
    display: none !important;
  } */
  .home-page-item span:hover {
    background: none;
  }

  .link-text-wrapper1,
  .link-text-wrapper2,
  .link-text-wrapper3,
  .link-text-wrapper4 {
    height: 54px;
  }
}

@media (max-width: 1330px) {
  .home-page-item span {
    height: 65px;
    font-size: 55px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .home-page-wrapper {
    padding: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    max-width: 100vw;
    -webkit-overflow-scrolling: touch;
    height: auto;
  }

  .home-page-item span:hover {
    background: none;
  }
}

@media (max-width: 480px) {
  .home-page-content-container {
    padding-top: 0;
  }

  .home-page-item {
    /* height: 12.5vw; */
    font-size: 10vw;
  }

  .home-page-item span {
    height: 11.5vw;
    font-size: 10vw;
  }
}

.hiden {
  opacity: 0;
}

.sidebar-overflow-content {
  display: block;
  position: absolute;
  right: 10vw;
  top: 10px;
  bottom: 10px;
  z-index: 9999;
  border: none;
  /* background: #f7f5f2; */
  overflow: hidden;
  /* box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09); */
  padding: 108px 37px 0;
  /* overflow-y: auto; */
  min-width: 360px;
  max-width: 700px;
  width: calc(100vw - 600px);
  box-sizing: border-box;
}

.sidebar-overflow-content.fadein {
  -webkit-animation: fadein 0.5s linear forwards;
  animation: fadein 0.5s linear forwards;
  opacity: 0;
}

@media screen and (max-width: 787px) {
  .sidebar-overflow-content.fadein {
    -webkit-animation: slideIn 0.3s linear forwards;
    animation: slideIn 0.3s linear forwards;
    opacity: 1;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@-webkit-keyframes slideIn {
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes slideIn {
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@-webkit-keyframes fadein {
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  100% {
    opacity: 1;
  }
}

.sidebar-overflow-content.fadeout {
  -webkit-animation: fadeout 0.5s linear forwards;
  animation: fadeout 0.5s linear forwards;
  opacity: 1;
}

@media screen and (max-width: 787px) {
  .sidebar-overflow-content.fadeout {
    -webkit-animation: slideOut 0.3s linear forwards;
    animation: slideOut 0.3s linear forwards;
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@-webkit-keyframes slideOut {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes slideOut {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@-webkit-keyframes fadeout {
  100% {
    opacity: 0;
  }
}

@keyframes fadeout {
  100% {
    opacity: 0;
  }
}

.sidebar-overflow-content.something_else {
  overflow-y: auto;
}

@media screen and (max-height: 835px) {
  .sidebar-overflow-content.design {
    overflow-y: auto;
  }
}

@media screen and (max-width: 1120px) {
  .sidebar-overflow-content.design {
    overflow-y: auto;
  }
}
.sidebar-content-wrapper {
  width: 100%;
}

h1 {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 38px;
}

.slide-in-left {
  /* -webkit-transition: all 1s ease-in-out; */
  /* display: flex; */
  /* transform: translateX(-100px); */
}

.slide-in-right {
  /* -webkit-transition: all 1s ease-in-out; */
  /* display: flex; */
  /* transform: translateX(110%); */
}

.slide-in-left > h1 {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 38px;
  padding: 0 15px;
}

.close {
  float: right;
  width: 35px;
  height: 35px;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  left: calc(100% - 30px);
  display: none;
  margin-top: -10px;
}
@media screen and (max-width: 1424px) {
  .sidebar-overflow-content {
    right: 5vw;
  }
}

/*Mobile styles*/
@media (max-width: 767px) {
  .sidebar-overflow-content {
    background-color: white;
    -webkit-overflow-scrolling: touch;
    min-width: 280px;
    padding: 30px 15px;
    height: 100%;
  }

  .sidebar-overflow-content.slide-in-left {
    top: 10px;
    overflow-y: auto;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .slide-in-left {
    -webkit-transform: translateX(-15px);
            transform: translateX(-15px);
  }

  .slide-in-right {
    -webkit-transform: translateX(130%);
            transform: translateX(130%);
  }

  .close {
    display: block;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar-overflow-content {
    -webkit-overflow-scrolling: touch;
    min-width: 280px;
    padding: 30px;
  }

  .sidebar-overflow-content.slide-in-left {
    margin-left: auto;
    margin-right: auto;
    right: 6vw;
    top: 10px;
  }

  .slide-in-left {
    -webkit-transform: translateX(-15px);
            transform: translateX(-15px);
  }

  .slide-in-right {
    -webkit-transform: translateX(130%);
            transform: translateX(130%);
  }
}

@media (max-width: 480px) {
  .sidebar-overflow-content {
    width: 80vw;
    height: auto;
    right: 0;
  }
}

.design-wrapper {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  padding-bottom: 30px;
}

.design-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-bottom: 10vh;
  /* margin-bottom: 45px; */
  width: 80%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

h2.active-link {
  /* background-color: #ee1c24; */
  color: #ee1c24;
  text-shadow: 0px 2px 3px rgba(214, 77, 77, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.design-about h4 {
  margin-bottom: 18px;
  font-size: 16px;
  font-family: "MetroSans-Regular";
}

.design-about-desc,
.design-pieces-desc {
  font-family: "MetroSans-Regular";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 60px;
  margin-bottom: 45px;
}
.design-about-approach {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.approach-item {
  width: 45%;
  margin-bottom: 19px;
}

.approach-item h3 {
  font-size: 16px;

  line-height: 20px;
  margin-bottom: 10px;
  font-family: "MetroSans-Regular";
}

.approach-item p {
  font-size: 14px;
  font-family: "MetroSans-Regular";
  font-weight: 400;
  line-height: 18px;
}

.design-pieces-card-item h3 {
  font-family: "MetroSans-Regular";
  font-size: 16px;
  line-height: 20px;
  margin-top: -10px;
}

.design-pieces-cards {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
}

.design-pieces-card-item {
  width: 50%;
  margin-bottom: 30px;
}

.design-pieces-card-item img {
  max-width: 95%;
  height: auto;
}

@media (max-width: 1120px) {
  .approach-item {
    display: block;
    width: 100%;
  }
}
@media (max-width: 768px) and (max-width: 1024px) {
  .design-header h2 {
    padding-right: 5px;
  }

  h4 {
    line-height: 1.2;
  }

  .design-content {
    padding-right: 15px;
  }

  .design-about-approach {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }
  .design-about-desc,
  .design-pieces-desc {
    margin-bottom: 30px;
  }
}

.contact-wrapper {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.contact-title h2 {
  /* background-color: #ee1c24; */
  color: #ee1c24;
  text-shadow: 0px 2px 3px rgba(214, 77, 77, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  margin-bottom: 160px;
  margin-bottom: 10vh;
}

.contact-row-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.contact-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 160px;
  width: 35%;
  border-top: 2px solid black;
  font-size: 20px;
  margin-bottom: 10vh;
}

.contact-item h3 {
  margin-top: 3px;
  font-family: "MetroSans-Bold";
  font-weight: 800;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.27px;
  margin-bottom: 10px;
}
.contact-info p {
  font-family: "MetroSans-Medium";
  letter-spacing: 0.27px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.contact-info {
  /* margin-left: auto; */
}

.privacy-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;

  font-family: "MetroSans-Medium";
  line-height: 1.5;
}

.address-wrapper,
.license-wrapper {
  min-width: 160px;
  width: 35%;
}

.address > span {
  margin-top: 15px;
  margin-right: auto;
  font-family: "MetroSans-Bold";
}

.address,
.license {
  display: -webkit-flex;
  display: flex;
  line-height: 1.5;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.address p,
.license p {
  text-align: left;
  margin-right: auto;
}

@media screen and (max-width: 1024px) {
  .contact-row-content,
  .privacy-info {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 0;
  }
  .contact-item {
    width: 100%;
  }
}

/*Mobile styles*/
@media (max-width: 768px) and (max-width: 1024px) {
  .contact-row-content {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 60px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-align-items: center;
            align-items: center;
  }
  .contact-item {
    margin-bottom: 50px;
  }
  .contact-title h2 {
    padding-right: 10px;
    margin-bottom: 0;
  }

  .address-wrapper,
  .license-wrapper {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }
}

.sourcing-wrapper {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: "MetroSans-Bold";
  font-size: 24px;
  line-height: 30px;
}

.sourcing-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 100%;
  /* margin-bottom: 150px; */
  margin-bottom: 10vh;
}

.sourcing-header h2 {
  /* background-color: #ee1c24; */
  color: #ee1c24;
  text-shadow: 0px 2px 3px rgba(214, 77, 77, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

@media (max-width: 1024px) {
  .sourcing-header {
    /* margin-bottom: 180px; */
  }
}
@media (max-width: 768px) {
  .sourcing-wrapper {
    padding-right: 5px;
  }
  .sourcing-header h2 {
    padding-right: 10px;
  }
  .sourcing-header {
    margin-bottom: 60px;
  }
  .sourcing-content {
    padding-bottom: 50px;
  }
}

.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  margin: auto;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.loading>div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #222147;
  border-color: #222147 transparent transparent transparent;
  border-radius: 50%;
  -webkit-animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loading>div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}

.loading>div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.loading>div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.post-shower-content {
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.something-else-wrapper {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.something-else-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 100%;
  margin-bottom: 10vh;
}

.something-else-header h2 {
  /* background-color: #ee1c24; */
  color: #ee1c24;
  text-shadow: 0px 2px 3px rgba(214, 77, 77, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.something-else-description > p {
  margin-bottom: 15px;
  font-family: "MetroSans-Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.something-else-post-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  margin-top: 50px;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  font-size: 14px;
  font-family: "MetroSans-Medium";
  font-weight: 500;
  line-height: 18px;
  padding-left: 10px;
}

.something-else-post-item strong {
  display: block;
  font-weight: normal;
  margin: 9px 0 7px;
  font-size: 11px;
  line-height: 18px;
  color: #2d2d29;
}

.something-else-post-date {
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #969694;
  font-size: 11px;
  line-height: 24px;
  font-style: italic;
}

.something-else-post-title {
  display: block;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #ff7112;
}

.something-else-post-link {
  font-size: 11px;
  line-height: 23px;
  font-style: italic;
  color: #ff7112;
  float: left;
  margin: 0 18px 0 0;
}

.something-else-post-link a {
  color: #ff7112;
}

.something-else-post-image {
  display: block;
  width: 100%;
  overflow: hidden;
  margin: 28px 0 41px;
}

.something-else-post-image img {
  max-width: 100%;
  max-height: 250px;
  height: auto;
  object-fit: contain;
}

.loadmore {
  text-align: center;
  margin: 20px 0;
  cursor: pointer;
}

@media (max-width: 768px) {
  .something-else-wrapper {
    padding-right: 15px;
    width: 95%;
  }

  .something-else-header h2 {
    padding-right: 10px;
  }
  .something-else-post-image img {
    max-height: 180px;
  }
}

@font-face {
  font-family: "MetroSans-Bold";
  src: url("/assets/fonts/Founders/FoundersGrotesk-Medium.woff2")
    format("woff2");
}

@font-face {
  font-family: "MetroSans-Medium";
  src: url("/assets/fonts/Metro-Sans/metro-sans-medium.eot");
  src: url("/assets/fonts/Metro-Sans/metro-sans-medium.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Metro-Sans/metro-sans-medium.woff2") format("woff2"),
    url("/assets/fonts/Metro-Sans/metro-sans-medium.woff") format("woff"),
    url("/assets/fonts/Metro-Sans/metro-sans-medium.ttf") format("truetype"),
    url("/assets/fonts/Metro-Sans/metro-sans-medium.svg#youworkforthem")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MetroSans-Regular";
  src: url("/assets/fonts/Metro-Sans/metro-sans-regular.eot");
  src: url("/assets/fonts/Metro-Sans/metro-sans-regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Metro-Sans/metro-sans-regular.woff2") format("woff2"),
    url("/assets/fonts/Metro-Sans/metro-sans-regular.woff") format("woff"),
    url("/assets/fonts/Metro-Sans/metro-sans-regular.ttf") format("truetype"),
    url("/assets/fonts/Metro-Sans/metro-sans-regular.svg#youworkforthem")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

html,
#root,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  /* background: #f7f5f2; */
  overflow-x: hidden;
}

h2 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 30px;
  font-family: "MetroSans-Bold";
  font-size: 24px;
  font-weight: 700;
  height: 32px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

