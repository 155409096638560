.contact-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.contact-title h2 {
  /* background-color: #ee1c24; */
  color: #ee1c24;
  text-shadow: 0px 2px 3px rgba(214, 77, 77, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  margin-bottom: 160px;
  margin-bottom: 10vh;
}

.contact-row-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
}

.contact-item {
  display: flex;
  flex-direction: column;
  min-width: 160px;
  width: 35%;
  border-top: 2px solid black;
  font-size: 20px;
  margin-bottom: 10vh;
}

.contact-item h3 {
  margin-top: 3px;
  font-family: "MetroSans-Bold";
  font-weight: 800;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.27px;
  margin-bottom: 10px;
}
.contact-info p {
  font-family: "MetroSans-Medium";
  letter-spacing: 0.27px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.contact-info {
  /* margin-left: auto; */
}

.privacy-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;

  font-family: "MetroSans-Medium";
  line-height: 1.5;
}

.address-wrapper,
.license-wrapper {
  min-width: 160px;
  width: 35%;
}

.address > span {
  margin-top: 15px;
  margin-right: auto;
  font-family: "MetroSans-Bold";
}

.address,
.license {
  display: flex;
  line-height: 1.5;
  flex-direction: column;
  font-size: 12px;
  width: fit-content;
  align-items: flex-end;
}

.address p,
.license p {
  text-align: left;
  margin-right: auto;
}

@media screen and (max-width: 1024px) {
  .contact-row-content,
  .privacy-info {
    flex-direction: column;
    margin-top: 0;
  }
  .contact-item {
    width: 100%;
  }
}

/*Mobile styles*/
@media (max-width: 768px) and (max-width: 1024px) {
  .contact-row-content {
    flex-direction: column;
    margin-top: 60px;
    flex-shrink: 0;
    align-items: center;
  }
  .contact-item {
    margin-bottom: 50px;
  }
  .contact-title h2 {
    padding-right: 10px;
    margin-bottom: 0;
  }

  .address-wrapper,
  .license-wrapper {
    justify-content: space-around;
  }
}
