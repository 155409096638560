.something-else-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.something-else-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10vh;
}

.something-else-header h2 {
  /* background-color: #ee1c24; */
  color: #ee1c24;
  text-shadow: 0px 2px 3px rgba(214, 77, 77, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.something-else-description > p {
  margin-bottom: 15px;
  font-family: "MetroSans-Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.something-else-post-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  margin-top: 50px;
  justify-content: space-around;
  font-size: 14px;
  font-family: "MetroSans-Medium";
  font-weight: 500;
  line-height: 18px;
  padding-left: 10px;
}

.something-else-post-item strong {
  display: block;
  font-weight: normal;
  margin: 9px 0 7px;
  font-size: 11px;
  line-height: 18px;
  color: #2d2d29;
}

.something-else-post-date {
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #969694;
  font-size: 11px;
  line-height: 24px;
  font-style: italic;
}

.something-else-post-title {
  display: block;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #ff7112;
}

.something-else-post-link {
  font-size: 11px;
  line-height: 23px;
  font-style: italic;
  color: #ff7112;
  float: left;
  margin: 0 18px 0 0;
}

.something-else-post-link a {
  color: #ff7112;
}

.something-else-post-image {
  display: block;
  width: 100%;
  overflow: hidden;
  margin: 28px 0 41px;
}

.something-else-post-image img {
  max-width: 100%;
  max-height: 250px;
  height: auto;
  object-fit: contain;
}

.loadmore {
  text-align: center;
  margin: 20px 0;
  cursor: pointer;
}

@media (max-width: 768px) {
  .something-else-wrapper {
    padding-right: 15px;
    width: 95%;
  }

  .something-else-header h2 {
    padding-right: 10px;
  }
  .something-else-post-image img {
    max-height: 180px;
  }
}
