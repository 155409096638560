.sourcing-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  font-family: "MetroSans-Bold";
  font-size: 24px;
  line-height: 30px;
}

.sourcing-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  /* margin-bottom: 150px; */
  margin-bottom: 10vh;
}

.sourcing-header h2 {
  /* background-color: #ee1c24; */
  color: #ee1c24;
  text-shadow: 0px 2px 3px rgba(214, 77, 77, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

@media (max-width: 1024px) {
  .sourcing-header {
    /* margin-bottom: 180px; */
  }
}
@media (max-width: 768px) {
  .sourcing-wrapper {
    padding-right: 5px;
  }
  .sourcing-header h2 {
    padding-right: 10px;
  }
  .sourcing-header {
    margin-bottom: 60px;
  }
  .sourcing-content {
    padding-bottom: 50px;
  }
}
