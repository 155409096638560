.sidebar-overflow-content {
  display: block;
  position: absolute;
  right: 10vw;
  top: 10px;
  bottom: 10px;
  z-index: 9999;
  border: none;
  /* background: #f7f5f2; */
  overflow: hidden;
  /* box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09); */
  padding: 108px 37px 0;
  /* overflow-y: auto; */
  min-width: 360px;
  max-width: 700px;
  width: calc(100vw - 600px);
  box-sizing: border-box;
}

.sidebar-overflow-content.fadein {
  -webkit-animation: fadein 0.5s linear forwards;
  animation: fadein 0.5s linear forwards;
  opacity: 0;
}

@media screen and (max-width: 787px) {
  .sidebar-overflow-content.fadein {
    -webkit-animation: slideIn 0.3s linear forwards;
    animation: slideIn 0.3s linear forwards;
    opacity: 1;
    transform: translateX(100%);
  }
}

@-webkit-keyframes slideIn {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideIn {
  100% {
    transform: translateX(0%);
  }
}
@-webkit-keyframes fadein {
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  100% {
    opacity: 1;
  }
}

.sidebar-overflow-content.fadeout {
  -webkit-animation: fadeout 0.5s linear forwards;
  animation: fadeout 0.5s linear forwards;
  opacity: 1;
}

@media screen and (max-width: 787px) {
  .sidebar-overflow-content.fadeout {
    -webkit-animation: slideOut 0.3s linear forwards;
    animation: slideOut 0.3s linear forwards;
    opacity: 1;
    transform: translateX(0%);
  }
}

@-webkit-keyframes slideOut {
  100% {
    transform: translateX(100%);
  }
}

@keyframes slideOut {
  100% {
    transform: translateX(100%);
  }
}

@-webkit-keyframes fadeout {
  100% {
    opacity: 0;
  }
}

@keyframes fadeout {
  100% {
    opacity: 0;
  }
}

.sidebar-overflow-content.something_else {
  overflow-y: auto;
}

@media screen and (max-height: 835px) {
  .sidebar-overflow-content.design {
    overflow-y: auto;
  }
}

@media screen and (max-width: 1120px) {
  .sidebar-overflow-content.design {
    overflow-y: auto;
  }
}
.sidebar-content-wrapper {
  width: 100%;
}

h1 {
  height: fit-content;
  font-size: 38px;
}

.slide-in-left {
  /* -webkit-transition: all 1s ease-in-out; */
  /* display: flex; */
  /* transform: translateX(-100px); */
}

.slide-in-right {
  /* -webkit-transition: all 1s ease-in-out; */
  /* display: flex; */
  /* transform: translateX(110%); */
}

.slide-in-left > h1 {
  height: fit-content;
  font-size: 38px;
  padding: 0 15px;
}

.close {
  float: right;
  width: 35px;
  height: 35px;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  left: calc(100% - 30px);
  display: none;
  margin-top: -10px;
}
@media screen and (max-width: 1424px) {
  .sidebar-overflow-content {
    right: 5vw;
  }
}

/*Mobile styles*/
@media (max-width: 767px) {
  .sidebar-overflow-content {
    background-color: white;
    -webkit-overflow-scrolling: touch;
    min-width: 280px;
    padding: 30px 15px;
    height: 100%;
  }

  .sidebar-overflow-content.slide-in-left {
    top: 10px;
    overflow-y: auto;
    justify-content: center;
  }

  .slide-in-left {
    transform: translateX(-15px);
  }

  .slide-in-right {
    transform: translateX(130%);
  }

  .close {
    display: block;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar-overflow-content {
    -webkit-overflow-scrolling: touch;
    min-width: 280px;
    padding: 30px;
  }

  .sidebar-overflow-content.slide-in-left {
    margin-left: auto;
    margin-right: auto;
    right: 6vw;
    top: 10px;
  }

  .slide-in-left {
    transform: translateX(-15px);
  }

  .slide-in-right {
    transform: translateX(130%);
  }
}

@media (max-width: 480px) {
  .sidebar-overflow-content {
    width: 80vw;
    height: auto;
    right: 0;
  }
}
