.home-page-wrapper {
  font-weight: 400;
  color: #000000;
  padding: 0px 130px;
  height: 100%;
}

.home-page-content-container {
  display: flex;
  justify-content: center;
  height: auto;
  flex-direction: column;
  padding-top: 10vh;
}

.logo {
  font-size: 38px;
  margin-right: 40px;
  width: 160px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.logo img {
  width: 100%;
  height: auto;
}

.header {
  display: flex;
  flex-direction: row;
  padding: 50px 0 0;
  /* position: absolute; */
}

.home-page-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* padding-top: 60px; */
  padding-bottom: 10px;
}

.loader .logo {
  width: 30vw;
}

.loader {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #f7f5f2;
  justify-content: center;
  align-items: center;
}

.fadeout {
  opacity: 0;
  transition: opacity 0.5s ease;
  transition-delay: 1.5s;
  pointer-events: none;
}

.fadein {
  opacity: 1;
  transition: opacity 0.5s ease;
  transition-delay: 0.5s;
  height: 100%;
}
/* .slider {
  position: relative;
  width: 200px;
  height: 136px;
  overflow: hidden;
  top: -106px;
  left: 50px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09);
  display: none;
}

.slider .slider-row {
  width: 200px;
  position: absolute;
  height: 136px;
  animation: slide 11s linear infinite;
} */
.link-text-wrapper1,
.link-text-wrapper2,
.link-text-wrapper3,
.link-text-wrapper4 {
  overflow: hidden;
  display: block;
  height: 70px;
  width: fit-content;
  letter-spacing: normal;
}

.link-text-wrapper1 span {
  transform: translate3d(0px, 0%, 0px);
  animation: raise-up-text 0.8s ease-out;
}

.link-text-wrapper2 span {
  transform: translate3d(0px, 0%, 0px);
  animation: raise-up-text 1s ease-out;
}

.link-text-wrapper3 span {
  transform: translate3d(0px, 0%, 0px);
  animation: raise-up-text 1.2s ease-out;
}

.link-text-wrapper4 span {
  transform: translate3d(0px, 0%, 0px);
  animation: raise-up-text 1.3s ease-out;
}

@keyframes raise-up-text {
  from {
    transform: translate3d(0px, 110%, 0px);
  }

  to {
    transform: translate3d(0px, 0%, 0px);
  }
}

@keyframes slide {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: -1000px;
  }
}

.home-page-item .link-text-wrapper1:hover ~ .slider,
.link-text-wrapper2:hover ~ .slider,
.link-text-wrapper3:hover ~ .slider,
.link-text-wrapper4:hover ~ .slider {
  display: unset;
}

.home-page-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: 61px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-family: "MetroSans-Bold";
  font-size: 60px;
  letter-spacing: -0.5px;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.disable-hover {
  display: none !important;
}

.home-page-item span {
  height: 75px;
  font-size: 65px;
  padding-right: 30px;
  font-family: "MetroSans-Bold";
  letter-spacing: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: -0.5px;
  /* display: block; */
  cursor: pointer;
  color: #000;
}

.active span {
  /* background-color: #ee1c24; */
  color: #ee1c24;
  text-shadow: 0px 2px 4px rgba(214, 77, 77, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.home-page-item span:hover {
  /* background-color: #ee1c24; */
  color: #ee1c24;
  text-shadow: 0px 2px 4px rgba(214, 77, 77, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

a {
  text-decoration: none;
  color: #000000;
}

.image-container {
  height: 25vh;
}

.image-carousel-container {
  width: 25vh;
  margin-top: 20px;
}

/*Mobile styles*/
@media (max-width: 768px) {
  .home-page-wrapper {
    padding: 30px 20px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    max-width: 100vw;
    -webkit-overflow-scrolling: touch;
    height: 100%;

    /* height: calc(100vh - 60px); */
  }

  .home-page-content {
    padding-top: 0px;
    height: 100%;
  }

  .logo {
    font-size: 22px;
    margin-right: 10px;
  }

  .logo img {
    max-width: 100px;
    max-height: 60px;
    object-fit: contain;
  }

  .header {
    padding: 0 0 5vh 0;
  }

  .home-page-item {
    font-size: 32px;
    height: 52px;
  }

  .home-page-item span {
    height: 40px;
    padding-right: 15px;
    width: fit-content;
  }

  /* .slider {
    display: none !important;
  } */
  .home-page-item span:hover {
    background: none;
  }

  .link-text-wrapper1,
  .link-text-wrapper2,
  .link-text-wrapper3,
  .link-text-wrapper4 {
    height: 54px;
  }
}

@media (max-width: 1330px) {
  .home-page-item span {
    height: 65px;
    font-size: 55px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .home-page-wrapper {
    padding: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    max-width: 100vw;
    -webkit-overflow-scrolling: touch;
    height: auto;
  }

  .home-page-item span:hover {
    background: none;
  }
}

@media (max-width: 480px) {
  .home-page-content-container {
    padding-top: 0;
  }

  .home-page-item {
    /* height: 12.5vw; */
    font-size: 10vw;
  }

  .home-page-item span {
    height: 11.5vw;
    font-size: 10vw;
  }
}

.hiden {
  opacity: 0;
}
